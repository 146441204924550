<template>
  <div class="Forgot__Container">
    <Parallax :images="ParallaxImages()" />
    <div class="Forgot">
      <div class="Forgot_Blur" />
      <div class="Forgot__header">
        <p class="title">
          {{ title }}
        </p>
      </div>
      <div class="forgot__content" v-show="!succeeded">
        <p class="label">{{ label }}</p>
        <div class="field">
          <LoginField
            ref="emailFieldRef"
            :input-type="'email'"
            @emailChanged="UpdateEmail($event)"
          />
        </div>
        <div class="Buttons__Container">
          <CTA
            class="Forgot__Btn"
            label="cancel"
            @clickButton="goBack"
            :color="'#d7321e'"
          />
          <CTA
            class="Forgot__Btn"
            label="Send"
            @clickButton="submit"
            :active="VerifyEmail(email)"
            :color="'#d7321e'"
          />
        </div>
      </div>
      <div class="forgot__content" v-show="succeeded">
        <p class="label">{{ info }}</p>
        <div class="Buttons__Container">
          <CTA label="login" @clickButton="goBack" :color="'#d7321e'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginField from './login/components/LoginField'
import CTA from '../components/button/CTA'
import api from '@/services/api'
import Parallax from '@/components/parallax/Parallax.vue'

export default {
  name: 'forgotPassword',
  components: {
    LoginField,
    CTA,
    Parallax
  },
  data () {
    return {
      email: '',
      isDisabled: false,
      succeeded: false
    }
  },

  computed: {
    title () {
      return 'Forgot password'
    },
    label () {
      return 'We will send an email for resetting your password'
    },
    placeholder () {
      return 'Email'
    },
    info () {
      return 'An email has been sent to your address.'
    }
  },

  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    async submit () {
      try {
        this.isDisabled = true
        const baseURL = String(window.location.origin)
        await api.auth.forgotPassword(this.email, baseURL)
        this.succeeded = true
      } catch (e) {
        console.error(e)
      } finally {
        this.isDisabled = false
      }
    },
    UpdateEmail (value) {
      this.email = value
    },
    goBack () {
      this.$router.push({ name: 'login' })
    },
    VerifyEmail (value) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
.Forgot__Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.Forgot__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.Forgot {
  position: relative;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 310px;
  width: 310px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Forgot_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.Forgot_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.Forgot__header {
  height: 13%;
}

.title {
  font-family: 'BaronDidotT';
  text-transform: uppercase;
  text-align: center;
  @include fluid-type($min_width, $max_width, $min_font * 2, $max_font * 1.2);
}

.forgot__content {
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  padding: 0 10%;
  font-family: 'Gotham Book';
  @include fluid-type($min_width, $max_width, $min_font * 1.1, $max_font * 0.7);
  text-align: center;
  line-height: 1.2;
}

.field {
  height: 20%;
}

.Buttons__Container {
  display: flex;
  justify-content: space-between;
  height: 20%;
}

.Forgot__Btn {
  height: 100%;
  width: 48%;
}
</style>
